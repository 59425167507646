<template>
  <v-dialog
    v-model="internalShowing"
    max-width="400px"
  >
    <v-card>
      <v-card-title>Observaciones chequeo</v-card-title>
      <v-card-text>
        <v-form
          v-model="formValid"
        >
          <b10-dynamic-form
            v-model="formModel"
            :schema="formSchema"
            :options="formOptions"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click.stop="clickCancelar"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click.stop="clickAceptar"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    punto: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      formValid: false,
      formModel: {
        observaciones: '',
      },
      formSchema: {
        type: 'object',
        properties: {
          observaciones: {
            type: 'string',
            title: 'Observaciones',
            readOnly: false,
            'x-display': 'textarea',
            'x-props': {
              clearable: true,
              'background-color': 'white',
            },
          },
        },
      },
      formOptions: {
        locale: 'es',
      },
    }
  },
  watch: {
    item: {
      handler: async function (newValue) {
        this.$set(this.formModel, 'observaciones', newValue.observaciones)
      },
      deep: true
    }
  },
  methods: {
    clickAceptar () {
      this.$emit('set-observaciones', { item: this.item, punto: this.punto, formData: this.formModel })
      this.internalShowing = false
    },
    clickCancelar () {
      this.internalShowing = false
    },
  }
}
</script>
